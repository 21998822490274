import AdvocateDivyaDetails from '../../components/about/advocates-details/kiran';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateKiranDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Kiran Kumar'>
      <NavigationMenu active='about' />
      <PageHeader
        title='Kiran Kumar'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateDivyaDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateKiranDetailsPage;
