import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/kiran.jpg';

const AdvocateKiranDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Kiran did his LLB from Osmania University Hyderabad. He
                enrolled as an advocate in the year 2022. He is specialised in
                the area of Civil and Criminal laws. He is currently practicing
                at Civil, Criminal and Family Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='Mr. P. Kiran Kumar' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Mr. P. Kiran Kumar BA.LLB</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateKiranDetails;
